import {
  Route,
  RouteFnProps,
} from "gatsby-theme-facet-default/src/interfaces/route"
import { translate } from "gatsby-theme-facet-default/src/helpers/grammar"

/**
 * Returns the routes for the header section of the "MainLayout".
 *
 * @function
 *
 * @param {RouteFnProps} param
 *
 * @returns {Route}
 */
export default ({ intl }: RouteFnProps): Route[] => [
  homeRoute({ intl }),
  {
    id: "physicians",
    path: "/physicians",
    title: translate(intl, "header.menu.physicians"),
  },
  {
    id: "contact",
    path: "/contact",
    title: translate(intl, "header.menu.contactUs"),
  },
]

/**
 * Returns the route for the home page.
 *
 * @function
 *
 * @param {RouteFnProps} param
 *
 * @returns {Route}
 */
export const homeRoute = ({ intl }: RouteFnProps): Route => ({
  id: "homepage",
  path: "/",
  title: translate(intl, "header.menu.home"),
})
